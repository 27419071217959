import GymJunkieLogo from '@images/gymJunkieLogo.svg'
import { HeadFC, Link } from 'gatsby'
import * as React from 'react'

import { Layout } from '@styles/layout'
import { MOBILE_BREAK_POINT } from '@styles/styles'
import styled from 'styled-components'

const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
}

const NotFoundPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Logo src={GymJunkieLogo} />
        <Header>404</Header>
        <Header>page not found</Header>
        <BodyText>Something went wrong 😕</BodyText>
        <ButtonText to='/'>Go back home</ButtonText>
      </Wrapper>
    </Layout>
  )

  return (
    <main style={pageStyles}>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry 😔, we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to='/'>Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Not found</title>

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 4em;
  height: auto;
  object-fit: contain;
  margin-bottom: 2em;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 15vw;
  }
`

const Header = styled.h2`
  color: ${(props) => props.theme.colors.text1};
  font-size: ${(props) => props.theme.fontSize.desktop.header}em;
  font-weight: 300;
  margin-top: 0.2em;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.header}em;
  }
`

const BodyText = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.normal};
  color: ${(props) => props.theme.colors.text2};
  font-size: ${(props) => props.theme.fontSize.desktop.body}em;
  font-weight: 400;
  /* text-shadow: 0px 3px 2px #000; */
  margin-top: 10px;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.body}em;
  }
`

const ButtonText = styled(Link)`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSize.desktop.large}em;
  font-weight: 600;
  text-shadow: 0px 3px 2px #000;
  text-decoration: none;
  margin-top: 1em;
  cursor: pointer;
  transition: 250ms;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.large}em;
  }
`
